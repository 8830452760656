import React, { useState } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { mobile } from '~styles/global'
import { useCart } from '~context/siteContext'
import resolveLink from '~utils/resolveLink'
import Image from '~components/Image'
import Incrementer from '~components/Incrementer'
import Button from '~components/Button'
import prepareCheckout from '~utils/prepareCheckout'
import { PlusCircle } from './Svg'
import Num from '~components/Num'
import useSiteSettings from '~utils/useSiteSettings'
import SlideDown from 'react-slidedown'
import RichText from '~components/RichText'

const Item = ({ variant }) => {

	const { updateQty, removeItem } = useCart()

	const isVoucher = variant.content?.shopify?.variantTitle?.includes('$')

	return(
		<LineItem>
			<div>			
				<Link to={resolveLink(variant)} className='h3' >{variant.content.shopify.variantTitle ?? variant.content.shopify.productTitle}</Link>
				<Dimensions>{variant.content.dimensions}</Dimensions>
				{!isVoucher && <Price>$<span>{Math.floor(variant.content.shopify.price)}</span></Price>}
				<ProductIncrementer 
					onChange={value => updateQty(variant.content.shopify.variantId, value)}
					initialValue={variant.qty}
					maxValue={variant.maxQty}
				/>
			</div>
			<ProductImage image={variant.content?.thumb} aspectRatio={1} css={css``}/>
			<Close onClick={() => removeItem(variant.variantId)}><Cross/></Close>
		</LineItem>
	)
}

Item.propTypes = {
	variant: PropTypes.object,
}

const Cart = ({ open, className }) => {

	const { cartTotal, cart, closeCart, cartCount } = useCart()
	const [loading, setLoading] = useState(false)
	const { cartEmptyText, cartShippingText, cartDonationText } = useSiteSettings()
	const [giftOpen, setGiftOpen] = useState(false)
	const [note, setNote] = useState(null)

	const loadCheckout = () => {
		setLoading(true)
		prepareCheckout(cart, note)
	}
	return(
		<>
			<Wrap 
				className={className}
				css={css`
          transform: ${open ? 'translateX(0)' : 'translateX(100%)'};
          pointer-events: ${open ? 'all' : 'none'};
        `}
			>	
				<Top>
					<Bag>								
						<span className="h3">Bag</span> <Counter>{cartCount()}</Counter>
					</Bag>
					<Close 
						onClick={() => closeCart()}
						className='h3'
					>
						<Cross css={css`margin-right: var(--xxs);`}/> Close
					</Close>
				</Top>
				<CartItems>
					{cart.length ? 
						cart.map(variant => (
							<Item variant={variant} key={variant.content.shopify.variantId}/>
						))
						:
						<Empty>
							{cartEmptyText}
						</Empty> 
					}
				</CartItems>
				{cart.length > 0 &&
					<GiftMessage>
						<button onClick={() => setGiftOpen(!giftOpen)}>
							<h3>
								<NoteIcon>
									<PlusCircle css={css`transform: ${giftOpen ? 'rotate(45deg)' : 'none'};`}/>
								</NoteIcon>
								Is this a gift? Add a message
							</h3>
						</button>
						<SlideDown>
							{giftOpen &&
							<textarea className="h3" onChange={e => setNote(e.target.value)} value={note} />
							}	
						</SlideDown>
					</GiftMessage>
				}
				<Bottom>
					{cart.length ? 
						<>
							<CartMessage>
								<ShippingMessage><RichText content={cartShippingText}/></ShippingMessage>
								{cartDonationText?.length && <DonationMessage><RichText content={cartDonationText}/></DonationMessage>}
							</CartMessage>
							<Total>
								<h2>Total</h2>
								<h2>$<span>{cartTotal().toFixed(2)}</span></h2>
							</Total>
							<Checkout>
								<Button className='h2' onClick={() => loadCheckout(cart)}>{loading ? 'Loading...' : 'Checkout'}</Button>
							</Checkout>	
						</>
						:
						<KeepShopping>
							<Button className='h3' onClick={() => closeCart()}>
								Keep Shopping
							</Button>
						</KeepShopping>
					}
				</Bottom>
			</Wrap>
		</>
	)
}

const Wrap = styled.div`
  position: fixed;
  z-index: 3;
  width: 465px;
  top: 0;
  right: 0;
  bottom: 0;
  transition: transform 0.5s;
  box-sizing: border-box;
	background-color: var(--beige);
	border-left: 1px solid var(--black);
	z-index: 101;
	display: grid;
  grid-template-rows: max-content max-content 1fr;
	overflow-y: scroll;
  ${mobile}{
    width: calc(100% + 1px);
  }
`
const Top = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: var(--m);
	margin-bottom: var(--m);
`
const Close = styled.button`
  display: inherit;
`
const Cross = styled(PlusCircle)`
	width: 13px;
	height: 13px;
	transform: rotate(45deg);
	position: relative;
`
const Bag = styled.div`
	${mobile}{
		margin-top: 3px;
	}
`
const Counter = styled(Num)`
	margin-left: var(--xxs);
`
const CartItems = styled.div`
`
const Empty = styled.div`
	padding: 0 var(--m);
`
const LineItem = styled.div`
  display: grid;
	padding: var(--m);
	border-bottom: 1px solid var(--black);
	grid-template-columns: 1fr max-content max-content;
	grid-column-gap: var(--m);
	&:first-of-type{
		padding-top: 0px
	}
`
const ProductImage = styled(Image)`
	width: 84px;
  .gatsby-image-wrapper{
    height: 100%; 
    object-fit: cover;
  }
`
const Dimensions = styled.h3`
	margin-bottom: 2px;
`
const Price = styled.h3`
	margin-bottom: var(--xxs);
`
const ProductIncrementer = styled(Incrementer)`
	width: 56px;
	>button{
		padding: var(--xxs);
	}
`
const Bottom = styled.div`
	align-self: end;
`
const ShippingMessage = styled.h3`
	margin: var(--m) var(--m) var(--s);
`
const DonationMessage = styled(ShippingMessage)`
`
const CartMessage = styled.div`
	padding-bottom: calc(var(--s));
`
const Total = styled.div`
	display: flex;
	justify-content: space-between;
	padding: var(--m);
`
const Checkout = styled.div`
	border-top: 1px solid var(--black);
	padding: var(--s) var(--m);
`
const KeepShopping = styled.div`
 padding: var(--m);
`
const GiftMessage = styled.div`
	padding: var(--s) var(--m);
	border-bottom: 1px solid var(--black);
	align-self: start;
	h3{
		svg{
			transition: transform 0.3s;
			display: block;
		}
	}
	textarea{
		margin-bottom: 0;
		margin-top: var(--s);
		padding: var(--s);
	}
`

const NoteIcon = styled.div`
	width: 15px;
	display: inline-block;
	margin-right: 5px;
	transform: translateY(3.5px)
`

Cart.propTypes = {
	open: PropTypes.bool,
	className: PropTypes.string,
}

export default Cart
